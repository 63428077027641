@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  scroll-behavior: smooth;
}

body {
  background-color: black;
}

.gradient-01 {
  background-image: linear-gradient(270deg,
      + hsl(295deg 76% 51%) 0%,
      hsl(284deg 70% 73%) 26%,
      hsl(257deg 70% 86%) 39%,
      hsl(202deg 92% 90%) 50%,
      hsl(215deg 77% 81%) 61%,
      hsl(221deg 73% 70%) 74%,
      hsl(220deg 76% 51%) 100%);
  filter: blur(125px);
}

.hero-gradient {
  background: linear-gradient(97.86deg,
      #a509ff 0%,
      #34acc7 53.65%,
      #a134c7 100%);
}

.gradient-02 {
  position: absolute;
  width: 200px;
  height: 438px;
  top: 0px;
  right: 0px;

  background: #7aebfb;
  filter: blur(190px);
}

.glassmorphism {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.gradient-03 {
  position: absolute;
  width: 404px;
  height: 800px;
  left: 20%;
  top: 5%;

  background: rgba(149, 66, 232, 0.35);
  filter: blur(175px);
  transform: rotate(-114.2deg);
}

.gradient-04 {
  position: absolute;
  width: 304px;
  height: 100vh;
  left: 30%;
  top: 10%;

  background: rgba(45, 72, 152, 0.75);
  filter: blur(200px);
  transform: rotate(-53.13deg);
}

.gradient-05 {
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 0.04) 0%,
      rgba(255, 255, 255, 0) 100%);
}

.gradient-06 {
  background: linear-gradient(180deg, #0D0D0D 0%, #3A3A3A 100%);
}


.feedback-gradient {
  position: absolute;
  width: 200px;
  height: 316px;
  left: 0px;
  top: 0px;

  background: #8d39a3;
  filter: blur(175px);
}

.footer-gradient {
  position: absolute;
  width: 500px;
  height: 100px;
  left: 37%;
  bottom: 0;

  background: #65c4fa;
  filter: blur(150px);
}

.home-background {
  position: 'absolute';
  top: 80px;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  /* Adjust this value to change the opacity */
  z-index: 1;
}

.text-left {
  text-align: left !important;
}

.title-gradient {
  background: linear-gradient(90deg, #1E5CAB 0%, #31B836 102.95%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 768px) {
  nav {
    flex-direction: column;
    align-items: center;
  }

  .nav-links,
  .social-media-icons {
    width: 100%;
    justify-content: space-around;
  }
}

.gradient-button {
  /* Solid background color */
  background: transparent;
  /* This is the button's solid background color */
  color: white;
  border-radius: 6px;

  /* Gradient border */
  border: 4px solid transparent;
  /* The border must be set, but made transparent */
  border-image: linear-gradient(to right, #15202F, #1E5CAB) 1 stretch;

  font-size: 12px;
  text-align: center;
  display: inline-block;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.gradient-box {
  /* Solid background color */
  background: transparent;
  /* This is the button's solid background color */
  color: white;
  border-radius: 2px;

  /* Gradient border */
  border: 2px solid transparent;
  /* The border must be set, but made transparent */
  border-image: linear-gradient(to right, #1E5CAB, #0c4288, #15202F, #1E5CAB) 2 stretch;

  font-size: 12px;
  text-align: center;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.checkbox-custom {
  visibility: hidden;
  position: absolute;
}

.checkbox-custom + label {
  display: inline-block;
  color: white;
  border-radius: 50%; /* make it round */
  width: 15px; /* increase the size to fit the tick */
  height: 15px; /* increase the size to fit the tick */
  border: 2px solid transparent;
  border-image: linear-gradient(to right, #1E5CAB, #0c4288, #15202F, #1E5CAB) 2 stretch;
  font-size: 12px;
  text-align: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin-right: 2px;
  cursor: pointer;
  position: relative; /* Add position relative here */
}

/* When the custom checkbox is checked, add a checkmark */
.checkbox-custom:checked + label::after {
  content: '✓';
  font-size: 12px;
  line-height: 10px; /* Center the tick vertically */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the tick */
  color: white;
}
